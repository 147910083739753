import React, { useEffect, useState } from "react";
// import "../styles/style.css";
import globalVariables from "../globalVariables";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Modal from "../components/modalsuccessRegister";
import { trigger } from "react-hook-form";
import Select from "react-select";
import Spinner from "../components/spinner";
import ModalResponse from "../components/modalResponse";
import { motion } from "framer-motion";
// import { Turnstile } from "@marsidev/react-turnstile";
import ReCAPTCHA from "react-google-recaptcha";

const Pagination = ({ totalParts, currentPart, onChangePart }) => {
  const handlePageChange = (page) => {
    onChangePart(page);
  };

  return (
    <div className="flex justify-center mt-3">
      {Array.from({ length: totalParts }, (_, index) => index + 1).map(
        (page) => (
          <button
            key={page}
            // onClick={() => handlePageChange(page)}
            className={`mx-2 py-0 px-2 rounded-full cursor-default	  ${
              currentPart === page
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {page}
          </button>
        )
      )}
    </div>
  );
};

const Form_potencial_users = ({ clinic_id }) => {
  const { control, handleSubmit, reset, resetField } = useForm();

  const [selectedBreed, setSelectedBreed] = useState(null);
  const [dogsBreeds, setDogsBreeds] = useState([]);
  const [catsBreeds, setCatsBreeds] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState(false);
  const [additionalBreed, setAdditionalBreed] = useState(false);
  const [urlImg, setUrlImg] = useState("");
  const [petTypeSelect, setPetTypeSelect] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [image, setImage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedBreeds, setSelectedBreeds] = useState([]);
  const [prevInfo, setPrevInfo] = useState([]);
  const [prevInfoSelected, setPrevInfoSelected] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleRecaptchaChange = (value) => {
    setIsRecaptchaVerified(true);
    setIsFormReady(isCheckboxChecked && true);
  };

  const handleCheckboxChange = (isChecked) => {
    setIsCheckboxChecked(isChecked);
    setIsFormReady(isChecked && isRecaptchaVerified);
  };

  const estilo_1 = {
    fontSize: "medium",
    cursor: "pointer",
  };

  const getPrevInfo = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/prev_info`);
      if (response.data.success === true) {
        // console.log(response.data.content);
        setPrevInfo(
          response.data.content.map((prevInfo) => ({
            label: (
              <div className="w-11/12">
                <span style={{ fontWeight: "bold", color: "blue" }}>
                  {prevInfo.title}
                </span>
                <br />
                {prevInfo.description}
              </div>
            ),
            value: prevInfo.description,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  function array_to_send(array) {
    const array_to_send = [];

    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        array_to_send.push(array[i].value);
      }
    }
    return array_to_send;
  }

  const onSubmit = async (data) => {
    if (!isRecaptchaVerified || !isCheckboxChecked) {
      // Mostrar un mensaje de error o realizar alguna acción si el reCAPTCHA no ha sido verificado
      return;
    }
    setSpinner(true);
    // console.log(data);
    setEmail(data.email);
    const medicines_to_send = array_to_send(data.medicines);
    const allergies_to_send = array_to_send(data.allergies);
    const health_conditions_to_send = array_to_send(data.health_conditions);

    let secondary_breed = "";
    if (data.breeds[1]) {
      secondary_breed = data.breeds[1].value;
    }

    // reset();
    // return;
    try {
      const response = await axios.post(`${globalVariables.domain}/pet`, {
        pet_name: data.pet_name,
        pet_type: petTypeSelect,
        primary_breed: data.breeds[0].value,
        secondary_breed: secondary_breed,
        weight: data.weight,
        gender: data.gender,
        birthday: data.birthday,
        neutered_or_spayed: data.neutered_or_spayed,
        medications: medicines_to_send,
        allergies: allergies_to_send,
        health_conditions: health_conditions_to_send,
        additional_info:
          "i need help with " +
          prevInfoSelected.value +
          "\n" +
          data.additional_info,
        owner_name: data.owner_name,
        email: data.email,
        zip_code: data.zip_code,
        phone_number: data.phone_number,
        image_url: urlImg,
        clinic_id: clinic_id,
      });
      // console.log(response.data)
      if (response.data.success === true) {
        setShowModal(true);
        setSpinner(false);
        reset();
        setPart((prevPart) => prevPart - 2);
        // console.log(response.data.content);
        if (image !== "") {
          console.log("checkpoint 1");
          postImageUrl(image, response.data.content._id);
        } else {
          // console.log("checkpoint 2");
          request_to_ai(response.data.content._id);
        }
      }
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  const request_to_ai = async (id) => {
    try {
      const response = await axios.post(
        `${globalVariables.domain}/pet/message_to_ia/${id}`
      );
      console.log(response);
      setSpinner(false);
      setModalStatus(true);
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  };

  function base64ToBlob(base64String) {
    const sinPrefijo = base64String.split(",")[1];
    // Convertir la cadena base64 a un array de bytes
    const byteCharacters = atob(sinPrefijo);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Crear un objeto Blob a partir del array de bytes
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Asignar el nombre de archivo al Blob
    blob.name = "foto.jpg";
    const archivo = new File([blob], blob.name, { type: "image/jpeg" });
    return archivo;
    // return blob;
  }

  const postImageUrl = async (image, id) => {
    // console.log(image);
    const imageBlob = base64ToBlob(image);
    const formData = new FormData();
    formData.append("image", imageBlob);

    try {
      const responseImg = await axios.post(
        `${globalVariables.domain}/pet/image/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Asegúrate de establecer el tipo de contenido correcto
          },
        }
      );
      console.log(responseImg);
      setSpinner(false);
      setModalStatus(true);
      // if (responseImg.data.success === true) {
      // console.log(responseImg.data.content);
      // }
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getCatsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/cat_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setCatsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDogsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/dog_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setDogsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMedicines = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/medicine`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content);
        setMedicines(
          response.data.content.map((medicine) => ({
            value: medicine.name,
            label: medicine.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getConditions = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/condition`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content);
        setConditions(
          response.data.content.map((condition) => ({
            value: condition.name,
            label: condition.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllergies = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/allergie`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content);
        setAllergies(
          response.data.content.map((allergie) => ({
            value: allergie.name,
            label: allergie.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDogsBreeds();
    getCatsBreeds();
    getMedicines();
    getConditions();
    getAllergies();
    getPrevInfo();
  }, []);

  // useEffect(() => {
  //   console.log(checkbox);
  // }, [checkbox]);

  // const handleSelectChange = (e) => {
  //   setSelectedBreed(e.value);
  //   // console.log(e.value)
  // };

  const [value, setValue] = useState(null);

  const selectPetType = (event) => {
    setPetTypeSelect(event.target.value);
    // console.log(event)
    // setValue(null)
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    resetField("primary_breed");
    resetField("secondary_breed");
    setKey((prevKey) => prevKey + 1);
  }, [petTypeSelect]);

  useEffect(() => {
    resetField("secondary_breed");
  }, [additionalBreed]);

  const [key2, setKey2] = useState(0);

  useEffect(() => {
    resetField("medicines");
    resetField("allergies");
    resetField("health_conditions");
    setKey2((prevKey) => prevKey + 1);
  }, [additionalInformation]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (petTypeSelect !== "") {
      setDisabled(false);
    }
  }, [petTypeSelect]);

  //   const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false);
  const [part, setPart] = useState(1);

  const handleNext = () => {
    handleSubmit((data) => {
      setPart((prevPart) => prevPart + 1);
    })();
  };

  const handlePrevious = () => {
    setPart((prevPart) => prevPart - 1);
    setIsCheckboxChecked(false);
    setIsRecaptchaVerified(false);
    setIsFormReady(false);
  };

  const closeModal = () => {
    setShowModal(false);
    // setFormData(initialFormData);
    setIsCheckboxChecked(false);
    setIsRecaptchaVerified(false);
    setIsFormReady(false);
  };

  useEffect(() => {
    console.log(prevInfoSelected);
  }, [prevInfoSelected]);

  // const validateForm = () => {
  //   if ( !email ) {
  //     alert("Please fill in all required fields before proceeding.");
  //     return false;
  //   }
  //   return true;
  // };

  // const first_action = async () => {
  //   const onloadTurnstileCallback = await function () {
  //     window.turnstile.render("#turnstile", {
  //       sitekey: "0x4AAAAAAAZlWZM8V4v8XqLY",
  //       callback: function (token) {
  //         console.log(`Challenge Success ${token}`);
  //       },
  //     });
  //   };

  //   const script = document.createElement("script");
  //   script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
  //   script.onload = onloadTurnstileCallback;
  //   script.defer = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // };

  // useEffect(() => {
  //   first_action();
  // }, []);

  return (
    <div className="bg-white flex flex-col rounded-2xl justify-center items-center p-8 w-full md:w-1/2">
      {/* <script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback"
        defer
      ></script> */}
      {spinner === true && <Spinner />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full flex flex-col"
      >
        {part === 1 && (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit(onSubmit)}
            className="w-full"
          >
            <div className="max-h-[40rem] overflow-auto -mx-8">
              <div className="mx-8">
                <div className="w-full">
                  {/* <Turnstile
                    options={{ theme: "light" }}
                    scriptOptions={{ defer: true, async: true }}
                    siteKey="0x4AAAAAAAZlWZM8V4v8XqLY"
                  /> */}
                  {/* <div
                    id="turnstile"
                    className="cf-turnstile"
                    data-sitekey="0x4AAAAAAAZlWZM8V4v8XqLY"
                    data-callback="javascriptCallback"
                  ></div> */}
                  <div
                    id="turnstile"
                    className="cf-turnstile"
                    data-sitekey="0x4AAAAAAAZlWZM8V4v8XqLY"
                  ></div>
                  <label for="owner-name">
                    Your Name:<span className="required_badge">*</span>
                  </label>

                  <Controller
                    name="owner_name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Is required" }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="rounded-lg w-full xl:text-base py-2 text-xs px-4 border"
                          type="text"
                          id="owner-name"
                          name="owner-name"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="w-full">
                  <label for="owner-name">Pet Name</label>
                  <Controller
                    name="pet_name"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="rounded-lg w-full xl:text-base py-2 text-xs px-4 border"
                          type="text"
                          id="pet-name"
                          name="pet-name"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="w-full">
                  <label for="email">
                    Email:<span className="required_badge">*</span>
                  </label>

                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="rounded-lg w-full xl:text-base py-2 text-xs px-4 border"
                          type="text"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="w-full">
                  <label for="phone-number">
                    Phone Number:<span className="required_badge">*</span>
                  </label>
                  <Controller
                    name="phone_number"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Is required" }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="rounded-lg w-full xl:text-base py-2 text-xs px-4 border"
                          type="number"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="w-full">
                  <label>
                    Choose an Issue:<span className="required_badge">*</span>
                  </label>
                  <Select
                    className="py-2 -ml-4 w-full text-sm px-4"
                    isSearchable
                    options={prevInfo}
                    menuPlacement="top"
                    onChange={(e) => {
                      setPrevInfoSelected(e);
                      // console.log(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleNext}
              className="border w-full py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg"
            >
              Next
            </button>
          </motion.form>
        )}
        {part === 2 && (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit(onSubmit)}
            className=""
          >
            <div className="max-h-[40rem] overflow-auto -mx-8">
              <div className="mx-8">
                <div className="">
                  <div className="">
                    <label for="pet-type" className="text-sm">
                      Pet Type:<span className="required_badge">*</span>
                    </label>

                    <Controller
                      name="pet_type"
                      control={control}
                      defaultValue=""
                      rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            {...field.field}
                            className="py-2 w-full text-sm px-4"
                            options={[
                              { value: "dog", label: "Dog" },
                              { value: "cat", label: "Cat" },
                            ]}
                            onChange={(e) => {
                              field.onChange(e.value);
                              setPetTypeSelect(e.value);
                            }}
                          />
                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <label className="text-sm">
                      Breed (Max 2):<span className="required_badge">*</span>
                    </label>

                    <Controller
                      key={key}
                      name="breeds"
                      control={control}
                      defaultValue=""
                      rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            {...field.field}
                            className="py-2 w-full text-sm px-4"
                            options={
                              disabled === true
                                ? []
                                : petTypeSelect === "dog"
                                ? dogsBreeds
                                : catsBreeds
                            }
                            isMulti
                            isDisabled={petTypeSelect === "" ? true : false}
                            onChange={(e) => {
                              field.onChange(e);
                              if (e.length > 1) {
                                setDisabled(true);
                              } else {
                                setDisabled(false);
                              }
                            }}
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-col">
                    <label className="text-sm" for="birthday">
                      Birthday or Approximate:
                      <span className="required_badge">*</span>
                    </label>
                    <Controller
                      key={key}
                      name="birthday"
                      control={control}
                      defaultValue=""
                      rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <input
                            {...field}
                            className="rounded-lg w-full md:max-w-[40rem] py-2 lg:ml-4 text-sm px-4 border"
                            type="date"
                            id="birthday"
                            name="birthday"
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <label className="text-sm" for="weight">
                      Weight (lbs):<span className="required_badge">*</span>
                    </label>
                    <Controller
                      name="weight"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Is required" }}
                      render={({ field, fieldState }) => (
                        <>
                          <input
                            {...field}
                            className="rounded-lg w-full md:max-w-[40rem] py-2 lg:ml-4 text-sm px-4 border"
                            type="text"
                            inputMode="numeric"
                            onChange={(e) => {
                              let value = e.target.value;

                              // Remove any character that is not a digit or a dot
                              value = value.replace(/[^0-9.]/g, "");

                              // Replace multiple dots with only one
                              value = value.replace(/\.{2,}/g, ".");

                              // Replace any dot that is not the first one with an empty string
                              value = value.replace(/^(\d*\.\d*)\./, "$1");

                              // Replace leading zeros except if followed by a dot
                              value = value.replace(/^0+(?!\.)/, "");

                              // Update the value of the controlled field
                              field.onChange(value);
                            }}
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="">
                    <label className="text-sm" for="gender">
                      Gender:<span className="required_badge">*</span>
                    </label>

                    <Controller
                      name="gender"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Is required" }}
                      render={({ field, fieldState }) => (
                        <>
                          <select
                            {...field}
                            className="rounded-lg w-full md:max-w-[40rem] py-2 lg:ml-4 text-sm px-4 border"
                            name="gender"
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="text-sm" for="neutered-spayed">
                      Neutered or Spayed:
                      <span className="required_badge">*</span>
                    </label>
                    <Controller
                      name="neutered_or_spayed"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Is required" }}
                      render={({ field, fieldState }) => (
                        <>
                          <select
                            {...field}
                            className="rounded-lg w-full md:max-w-[40rem] py-2 lg:ml-4 text-sm px-4 border"
                            name="gender"
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="unsure">Unsure</option>
                          </select>
                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-col lg:flex-row lg:gap-2"></div>
            </div>
            <button
              onClick={handleNext}
              className="border w-full py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg"
            >
              Next
            </button>
            <button
              onClick={handlePrevious}
              className="border w-full py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg"
            >
              Previous
            </button>
          </motion.form>
        )}
        {part === 3 && (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="h-[40rem] overflow-auto">
              <div className="">
                <label
                  className="text-xs lg:text-md xl:text-base"
                  for="zipcode"
                >
                  Zip Code:<span className="required_badge">*</span>
                  <span className="text-base">
                    {" "}
                    Helps address region-specific issues.
                  </span>
                </label>
                <Controller
                  name="zip_code"
                  control={control}
                  defaultValue=""
                  rules={{ required: `Is required` }}
                  render={({ field, fieldState }) => (
                    <>
                      <input
                        {...field}
                        className="rounded-lg w-full xl:text-base py-1 text-xs px-4 border"
                        type="text"
                        maxlength="10"
                        id="zipcode"
                        name="zipcode"
                      />

                      {fieldState?.error?.message && (
                        <div className="flex items-center mt-2">
                          <span className="text-red-500 red">
                            {fieldState?.error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <label
                className="text-xs lg:text-sm text-blue-700 xl:text-sm"
                onClick={() => setAdditionalInformation(!additionalInformation)}
                style={estilo_1}
              >
                Medical Information (Click to add additional medical
                information)
              </label>
              <div
                id="myDiv"
                className={`${additionalInformation === false ? "hidden" : ""}`}
              >
                <div className="">
                  <div className="">
                    <label
                      className="text-xs lg:text-md xl:text-base"
                      for="current-medications"
                    >
                      Current Medications:
                    </label>
                    <Controller
                      key={key2}
                      name="medicines"
                      control={control}
                      defaultValue=""
                      // rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            {...field.field}
                            className="xl:text-base py-2 w-full -ml-4 text-xs px-4"
                            isSearchable
                            options={medicines}
                            isMulti
                            onChange={(e) => {
                              field.onChange(e);
                              // console.log(e);
                            }}
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="">
                    <label
                      className="text-xs lg:text-md xl:text-base"
                      for="allergies"
                    >
                      Allergies:
                    </label>
                    <Controller
                      key={key2}
                      name="allergies"
                      control={control}
                      defaultValue=""
                      // rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            {...field.field}
                            className="xl:text-base py-2 w-full -ml-4 text-xs px-4"
                            isSearchable
                            options={allergies}
                            isMulti
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <label
                      className="text-xs lg:text-md xl:text-base"
                      for="health-conditions"
                    >
                      Past or Present Health Conditions:
                    </label>
                    <Controller
                      key={key2}
                      name="health_conditions"
                      control={control}
                      defaultValue=""
                      // rules={{ required: `Is required` }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            {...field.field}
                            className="xl:text-base py-2 w-full -ml-4 text-xs px-4"
                            isSearchable
                            options={conditions}
                            isMulti
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />

                          {fieldState?.error?.message && (
                            <div className="flex items-center mt-2">
                              <span className="text-red-500 red">
                                {fieldState?.error?.message}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <label
                className="text-xs lg:text-md xl:text-sm"
                for="additional-information"
              >
                Is there any other information you'd like to share about your
                pet?
              </label>

              <Controller
                name="additional_info"
                control={control}
                defaultValue=""
                // rules={{ required: "Is required" }}
                render={({ field, fieldState }) => (
                  <>
                    <textarea
                      {...field}
                      className="rounded-lg w-full mt-2 xl:text-base py-1 text-xs px-4 border"
                      id="additional-information"
                      name="additional-information"
                      rows="4"
                    ></textarea>

                    {fieldState?.error?.message && (
                      <div className="flex items-center mt-2">
                        <span className="text-red-500 red">
                          {fieldState?.error?.message}
                        </span>
                      </div>
                    )}
                  </>
                )}
              />
              <div className="">
                <div className="">
                  <label className="text-xs xl:text-sm" for="image-upload">
                    Include a photo of the affected area of your pet if it could
                    aid in the assessment:
                  </label>
                  <input
                    className="rounded-lg w-full mt-2 xl:text-base py-1 text-xs px-4 border"
                    type="file"
                    id="imageUpload"
                    name="imageUpload"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
              <div className="w-full mt-6 flex items-center justify-center">
                <ReCAPTCHA
                  sitekey="6LePRtYpAAAAAF4UPv_E1ePYBFwV52Xik2Tl95X2"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <label className="checkbox-label">
                <input
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                  checked={isCheckboxChecked}
                  type="checkbox"
                  id="acknowledge-ai-opinion"
                  name="acknowledge-ai-opinion"
                />
                <span>
                  I understand and acknowledge that the diagnosis provided is
                  based on artificial intelligence and should not be considered
                  as professional medical advice.{" "}
                  <a href="https://samedayvet.com/privacypolicy">
                    Privacy Policy
                  </a>
                </span>
              </label>
            </div>
            <div className="submit_button_wrapper">
              <input
                disabled={!isFormReady}
                className={`${
                  !isFormReady ? "checkbox_false" : ""
                } input_submit`}
                id="submit_form"
                type="submit"
                value="Free AI Pet Health Assessment"
              />
            </div>
            {/* <button
              type="button"
              onClick={handlePrevious}
              className="border py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg"
            >
              Previous
            </button> */}
            {/* <button type="submit" className="border py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg">
            Next
          </button> */}
            <button
              onClick={handlePrevious}
              className="border w-full py-1 mt-3 rounded-full bg-blue-500 text-white text-sm xl:text-lg"
            >
              Previous
            </button>
          </motion.form>
        )}
        <Modal
          showModal={showModal}
          closeModal={closeModal}
          clinic_id={clinic_id}
        >
          <p className="text-lg text-center -mt-0 md:-mt-0 z-10">
            Thank you for using AskMila.ai. Please check your email for the
            AI-generated insights
          </p>
        </Modal>
      </motion.div>
      <Pagination totalParts={3} currentPart={part} onChangePart={setPart} />
    </div>
  );
};

export default Form_potencial_users;
