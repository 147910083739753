import React from "react";
import Form_potencial_users from "../form/form_potencial_users";

export default function FormPotentialUserWillow() {
  const clinic_id = "65a3a97b623c3dbb797a8f98";

  return (
    <div className="w-full flex justify-center items-center ">
      <Form_potencial_users clinic_id={clinic_id} />
    </div>
  );
}
