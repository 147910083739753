import { useEffect } from "react";

function PageNotFound() {
  useEffect(() => {
    window.location.replace("https://askmila.app/");
  }, []);
  return <></>;
}

export default PageNotFound;
