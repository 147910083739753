import React, { useEffect, useState } from "react";
import "../styles/style.css";
import globalVariables from "../globalVariables";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";

import Select from "react-select";
import Spinner from "../components/spinner";
import ModalResponse from "../components/modalResponse";

function FormDentipet() {
  const { control, handleSubmit, reset, resetField } = useForm();

  const [dogsBreeds, setDogsBreeds] = useState([]);
  const [catsBreeds, setCatsBreeds] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState(false);
  const [additionalBreed, setAdditionalBreed] = useState(false);
  const [urlImg, setUrlImg] = useState("");

  const [petTypeSelect, setPetTypeSelect] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [image, setImage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [email, setEmail] = useState("");

  const estilo_1 = {
    fontSize: "medium",
    cursor: "pointer",
  };

  function array_to_send(array) {
    const array_to_send = [];

    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        array_to_send.push(array[i].value);
      }
    }
    return array_to_send;
  }

  const onSubmit = async (data) => {
    setSpinner(true);
    // console.log(data);
    setEmail(data.email);
    // const medicines_to_send = array_to_send(data.medicines);
    // const allergies_to_send = array_to_send(data.allergies);
    // const health_conditions_to_send = array_to_send(data.health_conditions);

    let secondary_breed = "";
    if (data.breeds[1]) {
      secondary_breed = data.breeds[1].value;
    }

    // reset();
    // return;
    try {
      const response = await axios.post(
        `${globalVariables.domain}/pet/dentipet_pet`,
        {
          pet_name: data.pet_name,
          pet_type: petTypeSelect,
          primary_breed: data.breeds[0].value,
          secondary_breed: secondary_breed,
          birthday: data.birthday,
          additional_info: data.additional_info,
          owner_name: data.owner_name,
          email: data.email,
          zip_code: data.zip_code,
          phone_number: data.phone_number,
          image_url: urlImg,
        }
      );
      // console.log(response.data)
      if (response.data.success === true) {
        // setSpinner(false);
        // console.log(response.data.content);
        // return;
        if (image !== "") {
          console.log("checkpoint 1");
          postImageUrl(image, response.data.content._id);
        } else {
          // console.log("checkpoint 2");
          request_to_ai(response.data.content._id);
        }
      }
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  const request_to_ai = async (id) => {
    try {
      const response = await axios.post(
        `${globalVariables.domain}/pet/dentipet/message_to_ia/${id}`
      );
      console.log(response);
      setSpinner(false);
      setModalStatus(true);
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  };

  function base64ToBlob(base64String) {
    const sinPrefijo = base64String.split(",")[1];
    // Convertir la cadena base64 a un array de bytes
    const byteCharacters = atob(sinPrefijo);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Crear un objeto Blob a partir del array de bytes
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Asignar el nombre de archivo al Blob
    blob.name = "foto.jpg";
    const archivo = new File([blob], blob.name, { type: "image/jpeg" });
    return archivo;
    // return blob;
  }

  const postImageUrl = async (image, id) => {
    // console.log(image);
    const imageBlob = base64ToBlob(image);
    const formData = new FormData();
    formData.append("image", imageBlob);

    try {
      const responseImg = await axios.post(
        `${globalVariables.domain}/pet/dentipet/image/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Asegúrate de establecer el tipo de contenido correcto
          },
        }
      );
      console.log(responseImg);
      setSpinner(false);
      setModalStatus(true);
      // if (responseImg.data.success === true) {
      // console.log(responseImg.data.content);
      // }
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getCatsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/cat_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setCatsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDogsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/dog_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setDogsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDogsBreeds();
    getCatsBreeds();
  }, []);

  const selectPetType = (event) => {
    setPetTypeSelect(event.target.value);
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    resetField("primary_breed");
    resetField("secondary_breed");
    setKey((prevKey) => prevKey + 1);
  }, [petTypeSelect]);

  useEffect(() => {
    resetField("secondary_breed");
  }, [additionalBreed]);

  const [key2, setKey2] = useState(0);

  useEffect(() => {
    resetField("medicines");
    resetField("allergies");
    resetField("health_conditions");
    setKey2((prevKey) => prevKey + 1);
  }, [additionalInformation]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (petTypeSelect !== "") {
      setDisabled(false);
    }
  }, [petTypeSelect]);

  return (
    <>
      {spinner === true && <Spinner />}
      <ModalResponse
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        email={email}
      />
      <div className="container">
        <div className="form-container">
          <div className="form-container">
            {/* <p>
              Complete the form below to receive instant AI-driven insights
              about your pet's health, based on the information you provide
              about symptoms and concerns. This will help guide your next steps
              in pet care.
            </p> */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field_group">
                <div className="field_input--wrap">
                  <label for="pet-name">
                    Pet Name: <span className="required_badge">*</span>
                  </label>
                  <Controller
                    name="pet_name"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="input_field"
                          type="text"
                          id="pet-name"
                          name="pet-name"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="field_input--wrap">
                  <label for="pet-type">
                    Pet Type:<span className="required_badge">*</span>
                  </label>

                  <Controller
                    name="pet_type"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <Select
                          {...field.field}
                          className="input_field"
                          options={[
                            { value: "dog", label: "Dog" },
                            { value: "cat", label: "Cat" },
                          ]}
                          onChange={(e) => {
                            field.onChange(e.value);
                            setPetTypeSelect(e.value);
                          }}
                        />
                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="field_group breed_group">
                <div className="field_input--wrap">
                  <label>
                    Breed (Max 2):<span className="required_badge">*</span>
                  </label>

                  <Controller
                    key={key}
                    name="breeds"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <Select
                          {...field.field}
                          className={`input_field`}
                          options={
                            disabled === true
                              ? []
                              : petTypeSelect === "dog"
                              ? dogsBreeds
                              : catsBreeds
                          }
                          isMulti
                          isDisabled={petTypeSelect === "" ? true : false}
                          onChange={(e) => {
                            field.onChange(e);
                            if (e.length > 1) {
                              setDisabled(true);
                            } else {
                              setDisabled(false);
                            }
                          }}
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              {/* )} */}
              <div className="field_group">
                <div className="field_input--wrap">
                  <label for="birthday">
                    Birthday or Approximate:
                    <span className="required_badge">*</span>
                  </label>
                  <Controller
                    key={key}
                    name="birthday"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="input_field"
                          type="date"
                          id="birthday"
                          name="birthday"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="field_input--wrap">
                  <label for="zipcode">
                    ZipCode:<span className="required_badge">*</span>
                  </label>
                  <Controller
                    name="zip_code"
                    control={control}
                    defaultValue=""
                    rules={{ required: `Is required` }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="input_field"
                          type="text"
                          maxlength="10"
                          id="zipcode"
                          name="zipcode"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <label for="additional-information">
                Is there any other information you'd like to share about your
                pet?
              </label>

              <Controller
                name="additional_info"
                control={control}
                defaultValue=""
                // rules={{ required: "Is required" }}
                render={({ field, fieldState }) => (
                  <>
                    <textarea
                      {...field}
                      className="input_field"
                      id="additional-information"
                      name="additional-information"
                      rows="4"
                    ></textarea>

                    {fieldState?.error?.message && (
                      <div className="flex items-center mt-2">
                        <span className="text-red-500 red">
                          {fieldState?.error?.message}
                        </span>
                      </div>
                    )}
                  </>
                )}
              />

              <div className="field_group">
                <div className="field_input--wrap">
                  <label for="image-upload">
                    Include a photo of the affected area of your pet if it could
                    aid in the assessment:
                  </label>

                  <input
                    className="input_field"
                    type="file"
                    id="imageUpload"
                    name="imageUpload"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>

              <div className="field_group">
                <div className="field_input--minwrap">
                  <label for="owner-name">
                    Pet Owner Name:<span className="required_badge">*</span>
                  </label>

                  <Controller
                    name="owner_name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Is required" }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="input_field"
                          type="text"
                          id="owner-name"
                          name="owner-name"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="field_input--minwrap">
                  <label for="email">
                    Email:<span className="required_badge">*</span>
                  </label>

                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <input {...field} className="input_field" type="text" />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>{" "}
                <div className="field_input--minwrap">
                  <label for="phone-number">
                    Phone Number:<span className="required_badge">*</span>
                  </label>
                  <Controller
                    name="phone_number"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Is required" }}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          className="input_field"
                          type="number"
                        />

                        {fieldState?.error?.message && (
                          <div className="flex items-center mt-2">
                            <span className="text-red-500 red">
                              {fieldState?.error?.message}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <label className="checkbox-label">
                <input
                  onChange={() => setCheckbox(!checkbox)}
                  type="checkbox"
                  id="acknowledge-ai-opinion"
                  name="acknowledge-ai-opinion"
                />
                <span>
                  I understand and acknowledge that the diagnosis provided is
                  based on artificial intelligence and should not be considered
                  as professional medical advice.{" "}
                  <a href="https://samedayvet.com/privacypolicy">
                    Privacy Policy
                  </a>
                </span>
              </label>

              <div className="submit_button_wrapper">
                <input
                  disabled={!checkbox}
                  className={`${
                    checkbox === false ? "checkbox_false" : ""
                  } input_submit`}
                  id="submit_form"
                  type="submit"
                  value="Free AI Pet Health Assessment"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormDentipet;
