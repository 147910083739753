import React, { useEffect, useState } from "react";
import axios from "axios";
import globalVariables from "../globalVariables";
import { render } from "@testing-library/react";

function Test() {
  const [response, setResponse] = useState("");
  const [input, setInput] = useState("");
  const [image, setImage] = useState("");
  const [base64img, setBase64Img] = useState("");
  const request_to_ai = async () => {
    if (image === "") {
      try {
        const response = await axios.post(
          `https://api.samedayvet.com/pet_app/request_to_gpt_4o_mini`,
          {
            prompt: input,
          }
        );

        console.log(response.data.content);
        setResponse(response.data.content);
      } catch (error) {
        console.error(error);
      }
    } else {
      const url_img = await postImageUrl(base64img);
      try {
        const response = await axios.post(
          `https://api.samedayvet.com/pet_app/request_to_gpt_4o_mini`,
          {
            prompt: input,
            image_url: url_img,
          }
        );

        console.log(response.data.content);
        setResponse(response.data.content);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const postImageUrl = async (img) => {
    try {
      const responseImg = await axios.post(
        `${globalVariables.domain}/pet_app/image_app`,
        {
          image: img,
        }
      );
      // console.log("respondse", responseImg.data.content);
      if (responseImg.data.success === true) {
        return responseImg.data.content;
        // setSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     setImage(reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }

  //   let img_base64 = "";
  // };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    let img_base64 = ""; // Variable para almacenar la cadena base64

    reader.onloadend = async () => {
      const base64String = reader.result;
      setImage(base64String); // Actualiza el estado para mostrar la imagen
      img_base64 = base64String; // Guarda la cadena base64 en una variable
      // console.log(img_base64);
      setBase64Img(img_base64);
      // await postImageUrl(img_base64);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <div>
          <textarea
            style={{ borderColor: "#000", borderWidth: 0.5, color: "#000" }}
            value={input}
            onChange={(val) => setInput(val.target.value)}
          />
        </div>
        <div>
          <input
            className="input_field"
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
        <div>{image && <img src={image} alt="Uploaded" />}</div>
        <div>
          <button
            style={{
              borderWidth: 1,
              borderColor: "#000",
              padding: 10,
              color: "#000",
              background: "#E0FFFF",
              marginTop: 20,
            }}
            onClick={request_to_ai}
          >
            Send
          </button>
        </div>
      </div>
      <p>{response}</p>
    </div>
  );
}

export default Test;
